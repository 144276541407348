import React from 'react';
import Toggle from "./interface/toggle";

export default class SoloToggle extends React.Component {
    static defaultProps = {
        showToggle: true
    }

    constructor (props){
        super(props);

        this.cookieName = 'showSoloPrices';
        this.state = {
            checked: this.props.checked,
            focused: false,
            showModal: props.showModal || false
        };
    }

    componentWillReceiveProps(nextProps){
        if(this.state.checked !== nextProps.checked) this.setState({checked:nextProps.checked});
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.checked !== prevProps.checked) {
            this.setState({checked:this.props.checked});
        }
    }

    onKeyUp = (event: KeyboardEvent): void => {
        if (!this.state.focused) return;
        if(event.key === 'Enter') this.onToggle();
    };

    onModalKeyUp = (event: KeyboardEvent): void => {
        if(event.key === 'Escape') this.onContinue();
    };

    onToggle= (e) => {
        this.setState({
            checked: !this.state.checked,
            showModal: true
        }, () => {
            window.addEventListener('keyup', this.onModalKeyUp);
        });
    };

    onContinue= (e) => {
        if(e) e.preventDefault();
        if(e) e.stopPropagation();
        window.dataLayer.push({ event: 'price_change_continue' });
        this.setState({
            checked: !this.state.checked,
            showModal: false
        }, () => { window.removeEventListener('keyup', this.onModalKeyUp); } );
    };

    onChangePricing = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            showModal: false
        }, () => {
            if(this.state.checked) {
                window.dataLayer.push({ event: 'price_change_solo' });
            } else {
                window.dataLayer.push({ event: 'price_change_per_person' });
            }
            if(this.props.setCookie){
                const cookieDate = new Date();
                cookieDate.setMonth(cookieDate.getMonth() + 1);
                document.cookie = "showSoloPrices=" + escape(1) + "; path=/" + ((cookieDate == null) ? "" : "; expires=" + cookieDate.toGMTString());
                window.location = window.location.origin + window.location.pathname;
            } else {
                window.removeEventListener('keyup', this.onModalKeyUp);
                var search = new URLSearchParams(window.location.search);
                search.set('soloPrices', this.state.checked ? 'true':'false');
                window.location = window.location.origin + window.location.pathname + '?' + search.toString();
            }
        })
    };

    renderModal = () => {
        return([
            <div key='modal-shroud' className="modal-shroud" onClick={ this.onContinue }>
                <div key='solo-modal-container'   id="solo-modal" className="modal solo-modal text-center" >
                    <div className="modal-close" onClick={ this.onContinue } aria-label="Close modal" type="button">&times;</div>
                    <div className="modal-content">
                        <h3 className="text-center">Price preference</h3>
                        <p>Your current viewing preference is: <strong>{ this.props.checked ? 'Single Pricing':'Per Person Pricing'}</strong></p>

                        <div className="cta-row">
                            <p className="button save-btn" onClick={ this.onChangePricing }>Change to <span >{ !this.props.checked ? 'Single':'Per Person'}</span></p>
                            <a onClick={ this.onContinue }>Continue with current preference</a>
                        </div>

                    </div>
                </div>
            </div>,

        ])
    };

    renderToggle = () => {
        return([
            <input key="switch--input--hidden" id="switch--input_solo" className="switch--input" type="checkbox" onChange={ () => {} } checked={this.state.checked}/>,
            <label tabIndex="0" onKeyUp={ this.onKeyUp } onFocus={ () => this.setState({focused: true}) } onBlur={ () => this.setState({focused: false}) } key="switch--toggle" htmlFor="switch--input_solo" className="switch" onClick={this.onToggle}>
                <div className="switch--label-container">
                    <span className="switch--label">Per Person</span>
                    <span className="switch--label">Solo</span>
                </div>
                <span className="switch--toggle"></span>
            </label>
        ])
    };

    render () {
        if (this.props.toggleOnly) return (
            <div className="switch-wrapper small text-center text-lg-left">
                { this.state.showModal && this.renderModal() }
                { this.renderToggle() }
            </div>
        );

        return (
            <div className="switch-wrapper mb-5 text-center text-lg-left">
                { this.state.showModal && this.renderModal() }
                {
                    this.props.showToggle ? (
                        <React.Fragment>
                            <p>View prices as:</p>
                            {this.renderToggle()}
                            <p className="mt-2">Your current viewing preference is: <br /><strong>{ this.props.checked ? 'Single Pricing':'Per-Person Pricing'}</strong></p>
                        </React.Fragment>
                    ) : ''
                }

            </div>
        );
    }
}